import DeepLinkButton from "./components/DeepLinkButton";
import JQuery from "jquery";

class Login {
  private confirmPopup: JQuery<HTMLElement>;
  private confirmPopupClose: JQuery<HTMLElement>;
  private studentError: JQuery<HTMLElement>;
  private studentErrorClose: JQuery<HTMLElement>;

  constructor() {
    this.confirmPopup = $(".account-popup");
    this.confirmPopupClose = $(".account-popup .button-close i");

    this.studentError = $(".student-error");
    this.studentErrorClose = $(".student-error .close");
    new DeepLinkButton(); //Add toast information

    this.confirmPopupClose.click(() => {
      this.Destroy();
    });

    this.studentErrorClose.click(() => {
      this.studentError.hide();
    });
  }

  Destroy() {
    $(this.confirmPopup).css("display", "none");
  }
}

$(window).on("load", () => {
  new Login();
});
